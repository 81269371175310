<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
      <!-- Card stats -->
    </base-header>

    <b-container fluid class="mt--7">
      <b-row>
        <b-col>
          <b-overlay :show="loading" rounded="md">
            <b-card no-body>
              <b-card-header class="border-0">
                <h3 class="mb-0 d-inline">{{ pageName }}</h3>
                <div class="d-inline float-right mt--1">
                  <div
                    id="id-prev-attendance"
                    class="icon-shape text-white rounded-circle shadow bg-light icon-action"
                    @click="prevAttendance"
                  >
                    <i class="fas fa-angle-left"></i>
                  </div>
                  <div
                    id="id-next-attendance"
                    class="icon-shape text-white rounded-circle shadow bg-light icon-action"
                    @click="nextAttendance"
                  >
                    <i class="fas fa-angle-right"></i>
                  </div>
                  <div
                    id="id-download-attendance"
                    class="icon-shape text-white rounded-circle shadow bg-info icon-action"
                    @click="downloadAttendance"
                  >
                    <i class="fas fa-download"></i>
                  </div>
                </div>
                <b-tooltip target="id-prev-attendance" triggers="hover">
                  先月
                </b-tooltip>
                <b-tooltip target="id-next-attendance" triggers="hover">
                  次月
                </b-tooltip>
                <b-tooltip target="id-download-attendance" triggers="hover">
                  CSVダウンロードする
                </b-tooltip>
              </b-card-header>
              <b-card-body>
                <div>
                  <b-form-group class="text-right">
                    <b-input-group size="sm">
                      <b-form-input type="search" v-model="search" placeholder="社員名前で検索"></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!search" @click="search = null">クリア</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </div>
                <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  stacked="md"
                  :filter="search"
                  :filter-included-fields="['full_name']"
                  @filtered="onFiltered"
                  show-empty
                  small
                  hover
                >
                </b-table>
              </b-card-body>
              <b-card-footer>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  size="md"
                ></b-pagination>
              </b-card-footer>
            </b-card>
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { ajax, common, config } from '../../utils';
import { helper } from '../common';

export default {
  data() {
    return {
      loading: true,
      items: [],
      fields: [
        { key: 'code', sortable: true, label: '社員番号' },
        { key: 'full_name', sortable: true, label: '名前' },
        { key: 'business_days', label: '就業日数', class: 'text-right' },
        { key: 'total_days', sortable: true, label: '勤務日数', class: 'text-right' },
        { key: 'night_days', sortable: true, label: '深夜日数', class: 'text-right' },
        { key: 'total_hours', sortable: true, label: '勤務時間', class: 'text-right' },
        { key: 'extra_hours', sortable: true, label: '残業時間', class: 'text-right' },
      ],
      currentPage: 1,
      perPage: config.rowsPerPage,
      search: null,
      totalRowsFilted: null,
    }
  },
  computed: {
    pageName: function() {
      return `${this.year}年${this.month}月${common.getPageName(this.$route.name)}`;
    },
    totalRows: function() {
      return this.search ? this.totalRowsFilted : this.items.length;
    },
    year: function() {
      const year = this.$route.query.year;
      if (year) {
        return year;
      } else {
        return common.getPrevMonthDict().year;
      }
    },
    month: function() {
      const month = this.$route.query.month;
      if (month) {
        return month;
      } else {
        return common.getPrevMonthDict().month;
      }
    },
  },
  created() {
    this.getAttendanceList(this.year, this.month);
  },
  methods: {
    getAttendanceList(year, month) {
      const vm = this;
      ajax.fetchGet(common.formatStr(config.api.expense.attendance_by_month, year, month)).then(data => {
        vm.items = data;
      }).catch(errors => {
        helper.show_errors(errors);
      }).finally(() => {
        vm.loading = false;
      });
    },
    downloadAttendance() {
      const results = this.items.map(item => ([
        item.code, item.business_days, item.total_days, 0, 0, 0, 0, 0, 0, item.total_hours, 0, item.extra_hours, item.night_days, 0, 0,
        null, null, null, null, null, null, null, null, null, null, null, null, null, null, null
      ]));
      const strTimestamp = common.formatDate(new Date(), 'YYYYMMDDhhmmssSSS');
      common.downloadCSV(common.arrayToCSV(results), `${this.year}年${this.month}月勤怠_${strTimestamp}`);
    },
    prevAttendance() {
      const date = new Date(`${this.year}-${this.month}-01`);
      const prevYm = common.getPrevMonthDict(date);
      this.$router.replace({ name: "expense-attendance", query: { ...prevYm } });
      this.loading = true;
      this.getAttendanceList(prevYm.year, prevYm.month);
    },
    nextAttendance() {
      const date = new Date(`${this.year}-${this.month}-01`);
      const nextYm = common.getNextMonthDict(date);
      this.loading = true;
      this.$router.replace({ name: "expense-attendance", query: { ...nextYm } });
      this.getAttendanceList(nextYm.year, nextYm.month);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsFilted = filteredItems.length;
    },
  },
}
</script>